<template>
  <a-field
    :field="field"
    :label="label"
  >
    <b-taginput
      :data="remainingOptions"
      :value="value"
      open-on-focus
      @typing="onTyping"
      @add="add"
      @remove="remove"
    >
      <template
        slot="selected"
        slot-scope="props"
      >
        <b-tag
          v-for="(tag, index) in props.tags"
        >
          {{ translateOption(tag) }}
        </b-tag>
      </template>
    </b-taginput>
  </a-field>
</template>

<script>

import _ from 'lodash'

import Mixin from './Form/Mixin'
import AField from './Form/AField'

export default {
  components: {
    AField
  },
  mixins: [Mixin],
  props: {
    options: {
      type: Array,
      requried: true
    },
    labels: {
      type: String,
      requried: true
    }
  },
  computed: {
    optionsMap() {
      let optionsMap = []
      _.forEach(this.options, option => {
        optionsMap.push({
          value: option,
          label: this.$t(`${this.labels}.${option}`)
        })
      })
      return optionsMap
    },
    translatedOptions() {
      return this.translateOptions(this.options)
    },
    availableOptions () {
      let self = this

      return self.typing ? _.filter(self.remainingOptions, option => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(self.typing.toLowerCase()) >= 0
      }) : self.remainingOptions
    },
    remainingOptions () {
      return _.filter(this.translatedOptions, item => !_.includes(this.translateOptions(this.value), item))
    }
  },
  methods: {
    onTyping (input) {
      this.typing = input
      // this.$emit('change', input)
    },
    translateOption(option) {
      return this.$t(`${this.labels}.${option}`)
    },
    unTranslateOption(option) {
      console.log('untranslatin', option)
      const found = _.find(this.optionsMap, { label: option })
      return found.value
    },
    translateOptions(originalOptions) {
      return _.map(originalOptions, option => this.$t(`${this.labels}.${option}`))
    },
    add (option) {
      let newValues = _.clone(this.value)
      newValues.push(this.unTranslateOption(option))
      this.update(newValues)
    },
    remove (option) {
      let newValues = _.clone(this.value)
      _.pull(newValues, option)
      this.update(newValues)
    }
  }
}
</script>
