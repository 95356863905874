<template>
  <b-field
    :label="label"
  >
    <b-autocomplete
      :value="stringValue"
      :data="availableOptions"
      :open-on-focus="true"
      :placeholder="$t('components.typeVehicleAutocomplete.label')"
      :expanded="true"
      icon="label"
      clearable
      @typing="onTyping"
      @input="clear"
      @select="updateWithRefresh"
    >
      <template #default="props">
        <span v-html="formatCode(props.option)" />
      </template>

      <!-- <template
          v-if="canAddNewVehicle"
          v-slot:footer
        >
            <div>
            Vehicle not in the list?
            </div>
            <div>
            <a @click="addNewVehicle">
                <span> Add new... </span>
            </a>
          </div>
        </template> -->
    </b-autocomplete>
  </b-field>
</template>

<script>
import { remove, find, indexOf, filter, forEach, assign, differenceWith, values, orderBy } from 'lodash'
import Mixin from '@/components/Form/Mixin'
import AutocompleteMixin from '@/components/Form/AutocompleteMixin'

var fuzzyFilter = function (search, text) {
  text = text ? text.toString().toLowerCase() : ''
  search = search ? search.toString().toLowerCase() : ''

  var searchTextIndex = 0
  for (var index = 0; index < text.length; index++) {
    if (text[index] === search[searchTextIndex]) {
      searchTextIndex += 1
    }
  }
  return searchTextIndex === search.length
}

export default {
  mixins: [Mixin, AutocompleteMixin],
  props: {
    toPick: {
      default: () => ['id', 'number', 'vehicleType.id', 'vehicleType.name']
    }
  },
  data () {
    return {
      typing: ''
    }
  },
  computed: {
    availableOptions () {
      let self = this
      let sortedOptions = orderBy(self.optionsArray.map(item => {
        if(!item.number) {
          item.number = ''
        }
        return item
      }), ['vehicleType.name', 'number'])

      return self.typing ? filter(sortedOptions, option => fuzzyFilter(self.typing.toLowerCase(), self.formatCode(option))) : sortedOptions
    },
    computedValue () {
      if(_.isObject(this.value)) {
        if(this.value.number) {
          return _.find(this.optionsArray, option =>  option.id === this.value.id )
        } else {
          return this.value.vehicleType && this.value.vehicleType.id ? _.find(this.optionsArray, option => option.vehicleType.id === this.value.vehicleType.id ) : null
        }
      }
      return null
    },
    stringValue () {
      if(this.computedValue) {
        return this.computedValue.number ? ` ${this.computedValue.vehicleType.name} ${this.computedValue.number}` : this.computedValue.vehicleType.name
      }
      return ''
    }
  },
  methods: {
    onTyping (input) {
      this.typing = input
    },
    formatCode (option) {
      const vehicleType = option.vehicleType && option.vehicleType.name ? option.vehicleType.name : ''
      return option.number ? `${vehicleType} ${option.number}` : `<b>${vehicleType}</b>`
    },
    async updateWithRefresh(value) {
      await this.update(null)
      if(!value.number) {
        value.number = null
      }
      await this.update(value)
    },
    clear(value) {

      if(!_.isUndefined(value) && !value) {
        this.typing = ''
        this.update(null)
      }
    }
  }
}

</script>
