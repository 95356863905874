<template>
  <div>
    <div class="columns">
      <div class="column">
        <a-select
          :manual-doc="userDoc"
          field="routes.failures.where.operator"
          :label="$t('operators.sg')"
          options="operators"
          null-option
          @change="updateOperator"
        />

        <type-vehicle-autocomplete
          v-if="where.operator"
          :manual-doc="userDoc"
          field="routes.failures.where.vehicle"
          :label="$t('failures.filters.vehicle')"
          :options="availableVehicles"
          @change="value => updateAndFetch('vehicle', value, true, true)"
        />

        <simple-taginput
          :manual-doc="userDoc"
          field="routes.failures.where.state"
          :label="$t('common.state')"
          :options="$store.getters['failures/fields'].state.options"
          labels="failures.options.state"
          null-option
          @change="(value) => updateAndFetch('state', value, true, true)"
        />
      </div>

      <div class="column">
        <a-input
          :manual-doc="userDoc"
          field="routes.failures.where.order_number_array"
          :label="$t('failures.props.order_number')"
          update-on-click
          @change="updateOrderNumber"
        />

        <a-input
          :manual-doc="userDoc"
          field="routes.failures.where.number_array"
          :label="$t('failures.ref.number')"
          update-on-click
          @change="updateNumber"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { keys } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import TypeVehicleAutocomplete from '@/components/TypeVehicleAutocomplete'
import SimpleTaginput from '@/components/SimpleTaginput'

export default {
  components: {
    TypeVehicleAutocomplete,
    SimpleTaginput
  },
  computed: {
    ...mapGetters({
      operators: 'operators/items',
      vehicles: 'vehicles/items'
    }),
    userDoc () {
      return { module: 'user' }
    },
    where () {
      return this.user.routes.failures.where
    },
    availableVehicles () {
      if(this.where.operator) {
        let vehicleTypes = _.map(this.where.operator.vehicleTypes, vehicleType => {
          return { vehicleType }
        })
        let vehicles = _.filter(this.vehicles, vehicle => vehicle.operator.id === this.where.operator.id)
        return _.concat(vehicleTypes, vehicles)
      }
      return []
    }
  },
  created () {
    if (this.where.operator) {
      this.$store.dispatch('vehicles/fetchAndAdd', { where: [['operator.id', '==', this.where.operator.id]]
      })
    }
  },
  methods: {
    keys,
    async updateAndFetch (prop, value, fetch = false, removeNumberFilters = false) {
      if(removeNumberFilters) {
        await this.removeNumberFilters()
      }

      await this.$store.dispatch('user/update', {
        path: `routes.failures.where.${prop}`,
        value
      })

      if(fetch) {
        this.$store.dispatch('failures/fetchDocs')
      }
    },
    async removeNumberFilters() {
      console.log('removing numberFilters')
      if(this.where.number_array) {
        await this.$store.dispatch('user/update', {
          path: `routes.failures.where.number_array`,
          value: null
        })
      }
      if(this.where.order_number_array) {
        await this.$store.dispatch('user/update', {
          path: `routes.failures.where.order_number_array`,
          value: null
        })
      }
    },
    async updateOperator (value) {
      if (value) {
        await this.$store.dispatch('vehicles/fetchAndAdd', { where: [['operator.id', '==', value.id]], limit: 0 })
      }

      await this.$store.dispatch('user/update', {
        path: `routes.failures.where.vehicle`,
        value: null
      })
      this.updateAndFetch('operator', value, true, true)
    },
    async updateOrderNumber (value) {
      if(value) {
        await this.updateOperator(null)
        this.updateAndFetch('state', [])
        this.updateAndFetch('number_array', null)
      }
      this.updateAndFetch('order_number_array', value, Boolean(value))
    },
    async updateNumber (value) {
      if(value) {
        await this.updateOperator(null)
        this.updateAndFetch('state', [])
        this.updateAndFetch('order_number_array', null)
      }
      this.updateAndFetch('number_array', value, Boolean(value))
    }
  }
}
</script>
